import { isString, trim } from 'lodash'
import { forwardRef, useCallback } from 'react'
// eslint-disable-next-line settle/preferred-modules
import type { TextFieldProps } from '@mui/material'
import * as $ from './TextField.styled'
import type { ITextFieldStyledProps } from './TextField.styled'

export interface ITextFieldProps extends Omit<TextFieldProps, 'error'>, ITextFieldStyledProps {
  'data-test-id'?: string
  error?: string | boolean
}

export const TextField = forwardRef<HTMLDivElement, ITextFieldProps>(
  ({ id, warning, children, size = 'medium', error, helperText, value, onBlur, onInput, onChange, ...props }, ref) => {
    const handleBlur: NonNullable<ITextFieldProps['onBlur']> = useCallback(
      (e) => {
        const trimmedValue = trim(e.target.value)
        const event = { ...e, target: { ...e.target, value: trimmedValue } }

        onBlur?.(event)

        if (value !== trimmedValue) {
          onChange?.(event)
        }
      },
      [onBlur, onChange, value],
    )

    const handleInput: NonNullable<ITextFieldProps['onInput']> = (e) => {
      const nativeEvent = e.nativeEvent as InputEvent
      const target = e.target as HTMLInputElement

      if (nativeEvent.inputType === 'insertFromPaste' && nativeEvent.data) {
        target.value = target.value.replace(nativeEvent.data, trim(nativeEvent.data))
      }

      onInput?.(e)
    }

    return (
      <$.TextField
        id={id}
        data-test-id={id}
        size={size}
        value={value}
        error={Boolean(error)}
        helperText={isString(error) ? error : helperText}
        {...(warning && { 'data-warning': true, warning })}
        {...props}
        onInput={handleInput}
        onChange={onChange}
        onBlur={handleBlur}
        ref={ref}
      >
        {children}
      </$.TextField>
    )
  },
)

TextField.displayName = 'TextField'
